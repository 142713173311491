import { createApp } from 'vue';
import App from './App.vue';
import BalmUI from 'balm-ui';
import 'balm-ui-css';
import router from './router';
import validatorRules from './validation/rules';
import 'balm-ui-css';

createApp(App)
  .use(BalmUI, {
    $validator: validatorRules
  })
  .use(router)
  .mount('#app');
