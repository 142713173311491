import {reactive} from "vue";

class Console {
  private _messages: string[] = [];

  public log(msg: string, context?: unknown) {
    const logMessage = msg + (context ? ` ${JSON.stringify(context)}` : '');
    this._messages.push(logMessage);
    console.log(logMessage)
  }

  get messages(): string[] {
    return this._messages;
  }
}

export const pageConsole = reactive(new Console());
