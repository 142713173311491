import { helpers } from 'balm-ui'; // Default Usage


export default {
  required: {
    validate(value: unknown) {
      return !helpers.isEmpty(value);
    },
    message: '%s is required'
  },
  numeric: {
    validate(value: string) {
      return /^[0-9]+$/.test(value);
    },
    message: '%s must be numeric'
  }
};
