
import { Vue } from 'vue-class-component';
import { auth } from '@/services/Auth';
import { useValidator } from 'balm-ui';

export default class Auth extends Vue {
  username!: string;
  password!: string;

  loading = false;

  validator = useValidator();
  validations = {
    username: {
      label: 'Username',
      validator: 'required'
    },
    password: {
      label: 'Password',
      validator: 'required'
    }
  };
  validationMessages:{ [fieldName: string]: any } = [];

  created(): void {
    this.username = '';
    this.password = '';
  }

  login(): void {
    this.validationMessages = {username: 'f', password: 'd'};
    console.log(this.validationMessages)
    const { valid, validMsg } = this.validator.validate({username: this.username, password: this.password});
    if (!valid) {
      this.validationMessages = validMsg;
      console.log(this.validationMessages)
      return;
    }

    this.loading = true;
    auth.login(this.username, this.password).then((success: boolean) => {
      this.loading = false;
      if (!success) {
        this.validationMessages = {username: 'Login failed.', password: ' '};
        console.log(this.validationMessages);
        return;
      }
    })
  }

  logout(): void {
    auth.logout();
  }

  get isLoggedIn(): boolean {
    return auth ? auth.isLoggedIn : false;
  }

  get loggedInUser(): string {
    return auth? auth.loggedInUsername : '';
  }
}
