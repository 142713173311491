import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fceb846"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "console" }
const _hoisted_2 = { class: "message" }
const _hoisted_3 = { class: "index" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_divider = _resolveComponent("ui-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
      return (_openBlock(), _createElementBlock("div", { key: message }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("pre", _hoisted_3, _toDisplayString(index), 1),
          _createElementVNode("pre", _hoisted_4, _toDisplayString(message), 1)
        ]),
        (!(index + 1 === _ctx.messages.length))
          ? (_openBlock(), _createBlock(_component_ui_divider, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}