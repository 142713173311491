
import { config } from '@/services/Config';
import { Vue } from 'vue-class-component';
import {auth} from "@/services/Auth";

export default class Environment extends Vue {
  environment!: string;

  changeEnvironment(event: Event): void {
    if (auth.isLoggedIn) {
      auth.logout();
    }
    config.setEnvironmentByName(this.environment);
  }

  get environmentOptions(): Array<{label:string, value:string}> {
    return config.environmentNames.map((name) => {
      return {
        label: name,
        value: name
      }
    });
  }
}
