import axios from 'axios';
import { reactive } from 'vue';
import qs from 'qs';
import {pageConsole} from "@/services/Console";
import {config} from "@/services/Config";

interface OAuthResponse
{
  access_token: string;
  token_type: string;
  expires_in: number,
  refresh_token: string
}

class Auth {
  private _tokenType = '';
  private _accessToken = '';
  private _loggedInUsername = '';

  public async login(username: string, password: string): Promise<boolean>
  {
    const oauthConfig = config.environment.oauth;
    try {
      const response = await axios.post<OAuthResponse>(
        `${oauthConfig.scheme}://${oauthConfig.domain}${oauthConfig.passwordUri}`,
        qs.stringify({
          'client_id': oauthConfig.clientId,
          'client_secret': oauthConfig.clientSecret,
          'grant_type': 'password',
          'username': username,
          'password': password
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      this._tokenType = response.data.token_type;
      this._accessToken = response.data.access_token;
      this._loggedInUsername = username;

      pageConsole.log(`Successfully logged in as user ${username}`);
      return true;
    } catch (error) {
      pageConsole.log(`Unable to login - ${error}`);
      return false;
    }
  }

  public logout(): void
  {
    const username = this._loggedInUsername;
    this._tokenType = '';
    this._accessToken = ''
    this._loggedInUsername = ''
    pageConsole.log(`Logged out of user ${username}`);
  }

  get isLoggedIn(): boolean
  {
    return !!this._accessToken;
  }

  get token(): string
  {
    return `${this._tokenType} ${this._accessToken}`;
  }

  get loggedInUsername(): string
  {
    return this._loggedInUsername;
  }
}

export const auth = reactive(new Auth());
