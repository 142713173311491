
import {Options, Vue} from 'vue-class-component';
import PaymentsClient = google.payments.api.PaymentsClient;
import PaymentDataRequest = google.payments.api.PaymentDataRequest;
import IsReadyToPayRequest = google.payments.api.IsReadyToPayRequest;
import "@google-pay/button-element";
import {PaymentCallback} from "@/models/PaymentCallback";
import {PaymentMethod} from "@/models/PaymentMethod";
import PaymentData = google.payments.api.PaymentData;

@Options({
  props: {
    costPence: Number,
    completeCallback: Function as unknown as PaymentCallback
  }
})
export default class GooglePay extends Vue {
  completeCallback!: PaymentCallback;
  validateCallback!: () => boolean;
  costPence!: number;

  get paymentRequest(): PaymentDataRequest {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],   // NOTE: PAN_ONLY only in non-prod environments
            allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD'],  // NOTE: depends on operator (@see /user/paymentmethods AcceptedPaymentCardTypes)
            billingAddressRequired: false
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'cybersource',
              gatewayMerchantId: '99001940'   // NOTE: depends on zone (@see /user/paymentmethods PSPaccount)
            }
          }
        }
      ],
      merchantInfo: {
        merchantName: 'RingGo Test Harness',
        // NOTE: not provided by the app - Required when PaymentsClient is initialized with an environment property of PRODUCTION.
        // (@see https://developers.google.com/pay/api/web/reference/request-objects#MerchantInfo)
        merchantId: 'TEST'
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',  // NOTE: 'FINAL' for buy time, 'ESTIMATED' for pre-auth
        totalPriceLabel: 'Total',
        totalPrice: (this.costPence / 100).toFixed(2),
        currencyCode: 'GBP',
        countryCode: 'GB'
      }
    };
  }

  onLoadPaymentData(event: CustomEvent) {
    const detail: PaymentData = event.detail;
    console.log("load payment data", detail);

    this.completeCallback(detail.paymentMethodData.tokenizationData.token, PaymentMethod.GooglePay, (status: boolean) => {return;});
  }

  onError(event: any) {
    console.error("error", event.error);
  }
}
