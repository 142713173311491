
import {Options, Vue} from 'vue-class-component';
import API from "@/services/API";
import { useValidator } from "balm-ui";
import { DurationUnit } from '@/models/DurationUnit';
import GooglePay from "@/components/GooglePay.vue";
import {PaymentMethod} from "@/models/PaymentMethod";
import ApplePay from "@/components/ApplePay.vue";
import {pageConsole} from "@/services/Console";

@Options({
  components: {
    ApplePay,
    GooglePay,
  },
})
export default class SessionForm extends Vue {
  vehicleId !: string;
  zoneId !: string;
  operatorId !: string;
  durationValue !: number|'';
  durationUnit !: string;

  page = 1;
  costPence = 150;

  validator = useValidator();
  validations = {
    vehicleId: {
      label: 'Vehicle ID',
      validator: 'required'
    },
    zoneId: {
      label: 'Zone ID',
      validator: 'required, numeric'
    },
    operatorId: {
      label: 'Operator ID',
      validator: 'required, numeric'
    },
    durationValue: {
      label: 'Duration Value',
      validator: 'required'
    },
    durationUnit: {
      label: 'Duration Unit',
      validator: 'required'
    },
  };
  validationMessages:{ [fieldName: string]: string } | [] = [];

  private api: API = new API();

  created(): void {
    this.vehicleId = '';
    this.zoneId = '';
    this.operatorId = '';
    this.durationValue = '';
    this.durationUnit = DurationUnit.Hour;
  }

  checkCost(): void {
    if (!this.validate()) {
      return;
    }

    this.api.checkCost(
      this.vehicleId,
      this.zoneId as unknown as number,
      this.durationValue as number,
      this.durationUnit
    ).then((costPence: number|false) => {
      if (costPence === false) {
        this.page = 1;
        return;
      }

      this.costPence = costPence;
      this.page = 3;
    });

    this.page = 2;
  }

  validate(): boolean
  {
    this.validationMessages = [];
    const { valid, validMsg } = this.validator.validate({
      vehicleId: this.vehicleId,
      zoneId: this.zoneId,
      operatorId: this.operatorId,
      durationValue: this.durationValue,
      durationUnit: this.durationUnit,
    });
    if (!valid) {
      this.validationMessages = validMsg;
      console.log(this.validationMessages)
      return false;
    }
    return true;
  }

  park(token: string, paymentMethod: PaymentMethod, completeCallback: (status: boolean) => void): void {
    if (!this.validate()) {
      return;
    }

    this.api.park(
      this.vehicleId,
      this.operatorId as unknown as number,
      this.zoneId as unknown as number,
      this.durationValue as number,
      this.durationUnit,
      paymentMethod,
      token
    ).then(completeCallback)
  }

  enumKeys<O, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  }

  get durationUnitOptions(): Array<{label:string, value:string}>
  {
    const arr = [];
    for (const key of this.enumKeys(DurationUnit)) {
      arr.push({
        label: key,
        value: DurationUnit[key]
      })
    }
    return arr;
  }
}
