
import {Options, Vue} from "vue-class-component";
import API from "@/services/API";
import ApplePayValidationResponse from "@/models/ApplePayValidationResponse";
import {PaymentCallback} from "@/models/PaymentCallback";
import {PaymentMethod} from "@/models/PaymentMethod";

@Options({
  props: {
    costPence: Number,
    completeCallback: Function as unknown as PaymentCallback
  }
})

export default class ApplePay extends Vue {
  completeCallback!: PaymentCallback;
  costPence!: number;
  isHidden = true;

  private api: API = new API();

  mounted() {
    var scripts = [
      "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js",
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.body.appendChild(tag);
    });

    if (window.ApplePaySession) {
      var merchantIdentifier = 'merchant.com.ctt.co.uk.ringgo-development';
      var promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
      promise.then((canMakePayments:boolean) => {
        if (canMakePayments) {
          this.isHidden = false;
          console.log('hi, I can do ApplePay');
        } else {
          console.log('ApplePay is possible on this browser, but not currently activated.');
        }
      });
    } else {
      console.log('ApplePay is not available on this browser');
    }
  }

  session !: any;

  finishPayment(success: boolean): void {
    const result = {
      "status": success ? window.ApplePaySession.STATUS_SUCCESS : window.ApplePaySession.STATUS_ERROR
    };

    this.session.completePayment(result);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onApplePayButtonClicked() {
    // Define ApplePayPaymentRequest
    const request = {
      "countryCode": "GB",
      "currencyCode": "GBP",
      "merchantCapabilities": [
        "supports3DS"
      ],
      "supportedNetworks": [
        "visa",
        "masterCard",
        "amex",
        "discover"
      ],
      "total": {
        "label": "Demo (Card is not charged)",
        "type": "final",
        "amount": this.costPence/100
      }
    };

    // Create ApplePaySession
    this.session = new window.ApplePaySession(3, request);

    this.session.onvalidatemerchant = async (event: any) => {
      // Call your own server to request a new merchant session.
      this.api.validateApplePay()
        .then((response: ApplePayValidationResponse|false) => {
          if(response === false ){
            console.log('Error')
          }
          this.session.completeMerchantValidation(response);
        });
    };



    this.session.onpaymentauthorized = (event: any) => {
      var applePaymentToken = event.payment.token.paymentData;

      this.completeCallback(btoa(JSON.stringify(applePaymentToken)), PaymentMethod.ApplePay, this.finishPayment);

    };

    this.session.oncancel = (event: any) => {
      // Payment cancelled by WebKit
      console.log('Payment Cancelled')
    };


    this.session.begin();
  }
}


