import { auth } from '@/services/Auth';
import axios from 'axios';
import SessionResponse from "@/models/SessionResponse";
import SessionRequestBody from "@/models/SessionRequestBody";
import {PaymentMethod} from "@/models/PaymentMethod";
import { pageConsole } from "@/services/Console";
import CheckCostRequestBody from "@/models/CheckCostRequestBody";
import CheckCostResponse from "@/models/CheckCostResponse";
import {config} from "@/services/Config";
import ApplePayValidationResponse from "@/models/ApplePayValidationResponse";

export default class API {
  public async checkCost(
    vehicleId: string,
    zoneId: number,
    durationValue: number,
    durationUnit: string,
  ): Promise<number|false> {
    if (!auth.isLoggedIn) {
      pageConsole.log('Must login before attempting to park')
      return false;
    }

    const body: CheckCostRequestBody = {
      VehicleId: vehicleId,
      ZoneId: zoneId,
      Duration: durationValue,
      Unit: durationUnit,
    };

    pageConsole.log('Checking cost', body);

    const apiConfig = config.environment.ringgo;
    try {
      const response = await axios.post<CheckCostResponse>(
        `${apiConfig.scheme}://${apiConfig.domain}${apiConfig.checkCostUri}`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': auth.token
          }
        }
      );
      if (response.data.Success) {
        pageConsole.log('Check cost successful', response.data);
        return response.data.Session.Costs.Total;
      }

      pageConsole.log('Check cost was not successful', response.data);
      return false;
    } catch (error) {
      pageConsole.log(`Unable to check cost - ${error}`);
      return false;
    }
  }

  public async park(
    vehicleId: string,
    operatorId: number,
    zoneId: number,
    durationValue: number,
    durationUnit: string,
    paymentMethod: PaymentMethod,
    token: string
  ): Promise<boolean> {
    if (!auth.isLoggedIn) {
      pageConsole.log('Must login before attempting to park')
      return false;
    }

    const body: SessionRequestBody = {
      VehicleId: vehicleId,
      OperatorId: operatorId,
      ZoneId: zoneId,
      Duration: durationValue,
      Unit: durationUnit,
      PaymentMethod: paymentMethod,
      PaymentMethodId: null, // TODO: can we change the API to remove this requirement
      PaymentMethodToken: token
    };

    pageConsole.log('Attempting to park', body);

    const apiConfig = config.environment.ringgo;
    try {
      const response = await axios.post<SessionResponse>(
        `${apiConfig.scheme}://${apiConfig.domain}${apiConfig.parkUri}`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': auth.token
          }
        }
      );
      if (response.data.Success) {
        pageConsole.log('Parking started successfully', response.data);
        return true;
      }

      pageConsole.log('Parking was not started successfully', response.data);
      return false;
    } catch (error) {
      pageConsole.log(`Unable to start session - ${error}`);
      return false;
    }
  }

  public async validateApplePay(): Promise<ApplePayValidationResponse|false> {
    if (!auth.isLoggedIn) {
      pageConsole.log('Must login before attempting to validate apple pay')
      return false;
    }

    const apiConfig = config.environment.ringgo;
    try {
      const response = await axios.get<ApplePayValidationResponse>(
        `${apiConfig.scheme}://${apiConfig.domain}${apiConfig.appleVerification}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': auth.token
          }
        }
      );
      if (response.data.Success) {
        pageConsole.log('Apple Pay verification successful', response.data);
        return response.data;
      }

      pageConsole.log('Apple Pay verification successful', response.data);
      return false;
    } catch (error) {
      pageConsole.log(`Unable to verify Apple Pay - ${error}`);
      return false;
    }
  }
}
