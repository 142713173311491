import {EnvironmentConfig, StagingConfig, TestConfig, UATConfig, LocalDevConfig} from "@/config/EnvironmentConfig";
import {pageConsole} from "@/services/Console";
import {reactive} from "vue";

class Config {
  private environments: {[key: string]: EnvironmentConfig} = {
    'Local Dev': LocalDevConfig,
    'Test': TestConfig,
    'Staging': StagingConfig,
    'UAT': UATConfig
  };

  private _environment: EnvironmentConfig = this.environments['Test'];

  get environmentNames(): string[] {
    return Object.keys(this.environments)
  }

  get environment(): EnvironmentConfig {
    return this._environment;
  }

  public setEnvironmentByName(environmentName: string) {
    if (!this.environmentNames.includes(environmentName)) {
      pageConsole.log(`${environmentName} is not a valid environment`)
      return;
    }

    this._environment = this.environments[environmentName];
    pageConsole.log(`Changed environment to ${environmentName}`);
  }
}

export const config = reactive(new Config());
