import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apple_pay_button = _resolveComponent("apple-pay-button")!

  return (!_ctx.isHidden)
    ? (_openBlock(), _createBlock(_component_apple_pay_button, {
        key: 0,
        buttonstyle: "black",
        type: "pay",
        locale: "en-GB",
        onClick: _ctx.onApplePayButtonClicked
      }, null, 8, ["onClick"]))
    : _createCommentVNode("", true)
}