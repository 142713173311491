
import { Options, Vue } from 'vue-class-component';
import Auth from '@/components/Auth.vue';
import Console from '@/components/Console.vue';
import HelloWorld from '@/components/HelloWorld.vue';
import SessionForm from '@/components/SessionForm.vue';
import Environment from "@/components/Environment.vue";

@Options({
  components: {
    Environment,
    Auth,
    Console,
    SessionForm,
    HelloWorld,
  },
})
export default class Home extends Vue {}
